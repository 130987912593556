import { useLocation, useMatches } from "@remix-run/react";
import { init as SentryInit, browserTracingIntegration } from "@sentry/remix";
import { useEffect } from "react";

SentryInit({
  dsn: import.meta.env.SENTRY_DSN,
  debug: import.meta.env.DEV,
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
});
